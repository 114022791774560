import { Flex, Text } from "aplication-yetz";
import { FieldProps, ErrorMessage } from "formik";
import { Toggle } from "../../Toggle";
import { When } from "../../When";
import { Variacao } from "../../../Hook/useProduto";
import { TextField } from "@mui/material";
import { Container } from "./styles";

export interface FormVoucherProps extends FieldProps {
    variacao: Variacao;
}

export function FormAgendaLimpeza(props: FormVoucherProps) {
    const { form } = props;
    const { setFieldValue, values, errors } = form;
    const { outlet_limpeza_agendada } = values;

    console.log(props);

    return (
        <Container>
            <div className={`container-form status `}>
                <Flex justifyContent="space-between" width="100%">
                    <Text color="gray300">AGENDAR LIMPEZA</Text>
                    <Toggle
                        titulo="Ativo"
                        active={outlet_limpeza_agendada ? true : false}
                        onClick={() => {
                            setFieldValue(
                                "outlet_limpeza_agendada",
                                !outlet_limpeza_agendada ? 1 : 0
                            );

                            if (outlet_limpeza_agendada) {
                                setFieldValue("outlet_dia_limpeza", "");
                                setFieldValue("outlet_hora_limpeza", "");
                            }
                        }}
                    />
                </Flex>
                <ErrorMessage
                    component="small"
                    name="outlet_limpeza_agendada"
                />
            </div>

            <When expr={outlet_limpeza_agendada}>
                <Flex justifyContent="space-between">
                    <div className="container-form">
                        <TextField
                            // disabled={hasVariacao}
                            className="input-text"
                            variant="standard"
                            type="number"
                            // maxlength={2}
                            // max="2"
                            value={values.outlet_dia_limpeza}
                            onChange={(e) => {
                                const value = parseInt(
                                    e.target.value.replace(/\D/g, "")
                                );

                                if (value > 31) return;

                                setFieldValue(
                                    "outlet_dia_limpeza",
                                    value ? value : ""
                                );
                            }}
                            name="outlet_dia_limpeza"
                            label="DIA DA LIMPEZA"
                        />
                        {errors.outlet_dia_limpeza ? (
                            <small>{errors.outlet_dia_limpeza}</small>
                        ) : (
                            <> </>
                        )}
                    </div>

                    <div className="container-form">
                        <TextField
                            className="input-text"
                            variant="standard"
                            type="number"
                            value={values.outlet_hora_limpeza}
                            onChange={(e) => {
                                const value = parseInt(
                                    e.target.value.replace(/\D/g, "")
                                );
                                if (value > 23) return;
                                setFieldValue(
                                    "outlet_hora_limpeza",
                                    value ? value : ""
                                );
                            }}
                            name="outlet_hora_limpeza"
                            label="HORA DA LIMPEZA"
                        />
                        {errors.outlet_hora_limpeza ? (
                            <small>{errors.outlet_hora_limpeza}</small>
                        ) : (
                            <> </>
                        )}
                    </div>
                </Flex>
            </When>
        </Container>
    );
}
